$primary: #2196f3;
$secondary: #777;
$danger: #f44336;
$success: #00A76D;
$success-light: #7de281;
$warning: #FFC107;
$info: #00bcd4;
$info-light: #96e7f3;
$gray-900: #333333;
$gray-light: #DCDCDC;
$gray-light_1: #eeeded;
$gray-light_2: #e2e2e2;
$gray-light_3: #c5c5c5;
$gray-light_4: #a2a2a2;
$gray-light_5: #efefef;
$gray-light_6: #fafafa;
$gray-light_7: #ced4da;
$gray-super-light: #fcfcfc;
$gray-blue: #607D8B;
$marron: #d81b60;
$purple: #5C2E91;
$purple-light: #9d6ad8;
$purple-light-2: #f0e4ff;
$orange: #ff851b;
$orange-light: #F6E3B0;
$orange-light-2: #fff8e4;
$yellow: #f6eb00;
$olive: #3d9970;
$navy: #001f3f;
$cerulean_blue: #1B75BB;
$navy_2: #2B328C;
$brown: #b18b24;
$pear: #67B11C;
$pomelo: #c6bb6b;
$black: #000000;
$green-dark: #10714f;
$brown-dark: #7f6b63;
$brown-light: #cdaf5f;
$teal: #009688;

@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "../../../node_modules/ngx-toastr/toastr-bs4-alert";

:root {
    --cropper-outline-color: rgba(255, 255, 255, .7) !important;
}

.dropdown-menu {
    border-radius: 0;
}
.modal {
    overflow: auto !important;
}
.modal-dialog {
    margin-bottom: 68px;
}
.btn-xs, .btn-group-xs > .btn {
    padding: 0.05rem 0.3rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}
.btn-orange-light {
    color: $gray-900;
    background-color: $orange-light;
    border-color: $orange-light;
}
.btn-export {
    color: $gray-900;
    border-color: #7edebd;
    background-color: #effff9;
}
.btn-export i {
    color: $danger;
}
.btn-export:hover {
    color: #fff !important;
    background-color: $info-light;
    border-color: $info-light;
}
.bg-marron {
    background-color: $marron;
}
.bg-purple {
    background-color: $purple;
}
.bg-purple-light {
    background-color: $purple-light;
}
.bg-orange {
    background-color: $orange;
}
.bg-orange-light {
    background-color: $orange-light !important;
}
.bg-orange-light-2 {
    background-color: $orange-light_2 !important;
}
.bg-success-light {
    background-color: $success-light;
}
.bg-olive {
    background-color: $olive;
}
.bg-navy {
    background-color: $navy;
}
.bg-navy-2 {
    background-color: $navy_2;
}
.bg-gray-light {
    background-color: $gray-light;
}
.bg-gray-light-1 {
    background-color: $gray-light-1;
}
.bg-gray-light-2 {
    background-color: $gray-light-2;
}
.bg-gray-light-3 {
    background-color: $gray-light-3;
}
.bg-gray-light-4 {
    background-color: $gray-light-4;
}
.bg-gray-light-5 {
    background-color: $gray-light-5;
}
.bg-gray-light-6 {
    background-color: $gray-light-6;
}
.bg-gray-super-light {
    background-color: $gray-super-light;
}
.bg-brown {
    background-color: $brown;
}
.bg-yellow {
    background-color: $yellow;
}
.bg-pear {
    background-color: $pear;
}
.bg-pomelo {
    background-color: $pomelo;
}
.bg-black {
    background-color: $black;
}
.bg-client-head {
    background-color: $cerulean_blue !important;
}
.bg-client-provider {
    background-color: $teal !important;
}
.border-gray {
    border-color: $gray-light_7 !important;
}
.text-purple {
    color: $purple;
}
.text-default {
    color: $gray-900;
}
.text-brown {
    color: $brown;
}
.text-orange {
    color: $orange;
}
.text-orange-light {
    color: $orange-light;
}
.text-yellow {
    color: $yellow;
}
.text-pear {
    color: $pear;
}
.text-pomelo {
    color: $pomelo;
}
.text-navy {
    color: $navy;
}
.text-navy-2 {
    color: $navy_2;
}
.text-gray-light {
    color: $gray-light;
}
.text-gray-light-4 {
    color: $gray-light_4;
}
.text-semi-bold {
    font-weight: 600;
}
.table-responsive {
    min-height: 250px;
    max-width: 100%;
}
.progress {
    background-color: $gray-light_7;
}
.link-default {
    color: $gray-900;
}
.link-default:hover {
    text-decoration: none;
}
.display-5 {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.2;
}
.z-index-2000 {
    z-index: 2000;
}
.z-index-3000 {
    z-index: 3000;
}
.z-index-4000 {
    z-index: 4000;
}
.modal {
    background: rgba(0, 0, 0, .4);
}
.modal .modal-dialog.modal-text-edit-dialog {
    margin-bottom: 0;
}

@mixin modal-fullscreen() {

    padding: 0 !important;
    width: 100%;
    max-width: none;
    min-height: 100%;
    margin: 0;
    background-color: $gray-light-6;

    .modal-content {
        min-height: 100%;
        border: 0;
        border-radius: 0;
        background-color: $gray-light-6;
    }

    .modal-body {
        overflow-y: auto;
    }
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-down($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .modal-fullscreen#{$infix} {
            @include modal-fullscreen();
        }
    }
}


